<template>
  <div>
    <div v-if="isMm">
      <CommonSectionLabel
        title="Vi har fokus på det, der ændrer samfundet"
        subtitle="Gå direkte til et af Mandag Morgens 4 fokusområder"
      />
      <ListPapersMM
        v-if="isSite('mm_dk')"
        type="slider"
        class="hidden lg:block"
      />
      <ListPapers v-else class="hidden lg:block" />
      <div class="container">
        <CommonSectionLabel
          :title="`Nyt fra ${
            indexStore.currentPaper.RecordId === 1
              ? 'Mandag Morgen'
              : indexStore.currentPaper.Name
          }`"
        />
        <LayoutColumns>
          <template v-slot:left>
            <CommonUserWidget />
            <AdsConceptAd id="mm-rec2" class="hidden lg:block" />
          </template>
          <template v-slot:main>
            <ListArticles
              :count="3"
              :offset="0"
              :reset-index="true"
              :frontend="true"
              :paper="indexStore.currentPaper.RecordId"
            />
            <NewsletterSignup />
          </template>
          <template v-slot:right>
            <ListCalendar title="Det sker på MM" :count="5" />
            <CommonPromo
              icon-type="conversation"
              header1="Netværk"
              header2="Få inspiration, sparring og professionelle relationer med Altinget og Mandag Morgens netværk"
              cta-label="Læs mere her"
              cta="https://www.altinget.dk/netvaerk/"
              disclaimer="Kontakt os og hør mere om netværk inden for dit område."
              image="https://legacy.altinget.dk/images/article/200180/72947.jpg"
              placement="side"
            />
          </template>
        </LayoutColumns>
      </div>
    </div>

    <div v-else>
      <CommonSectionLabel :title="'Latest'" />

      <div class="container">
        <LayoutColumns>
          <template v-slot:left>
            <AdsConceptAd id="altinget-rec2" />
            <ListQA
              v-if="currentPaper && currentPaper != 1"
              placement="side"
              label="MinisterQA"
              label-link="Routes.QA"
            />
            <ListNames v-if="currentPaper === 1" placement="side" />
            <CommonPromo
              placement="side"
              header1="14DaysTrial"
              header2="Ourmedia"
              cta-label="ReadMoreAndSignup"
              cta="Routes.Subscription"
              hide-if-logged-in
            />
          </template>
          <template v-slot:main>
            <ListArticles
              :paper="currentPaper"
              :count="8"
              show-more
              type="news"
            />
          </template>
          <template v-slot:right>
            <ListMostRead placement="side" />
            <AdsConceptAd id="altinget-rec3" />
            <ListJobs />
          </template>
        </LayoutColumns>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { isMm } = useBrand()

const props = defineProps<{
  paper?: number
}>()

const indexStore = useIndexStore()

const currentPaper = computed(() => {
  if (props.paper) {
    return props.paper
  }

  if (indexStore.currentPaper && indexStore.currentPaper.RecordId > 0) {
    return indexStore.currentPaper.RecordId
  } else {
    return 1 // Frontpage
  }
})
</script>
